export default class ApplicationConfig {
    //namespace
    static applicationName: string = 'UME Link';
    static productNameOnMarket: string = 'UME Link';
    static domains = ['umelink.kz', 'zheke.site', 'sauda.me', 'bilet.ws', 'netwrk.cc', 'resmi.ws', 'visite.kz'];
    static fonts = ['Montserrat Alternates', 'Pacifico', 'Lobster', 'Caveat', 'Roboto Mono', 'Inter', 'KZ Mon Amour One', 'Girloy', 'Philosopher'];

    //link
    static applicationLink: string = 'umelink.kz';
    static fullApplicationLink: string = 'https://umelink.kz';
    static applicationInfoLink: string = 'https://info.umelink.kz/';
    static thisProductBackendDirectory: string = 'https://umelink.kz/core/api/v2';
    static thisProductBackendMainPath2Url: string = `${ApplicationConfig.thisProductBackendDirectory}/main.php`;
    static thisProductBackendImagesPath: string = `${ApplicationConfig.thisProductBackendDirectory}/images/`;
    static thisProductBackendStyleUpdatePath: string = `${ApplicationConfig.thisProductBackendDirectory}/modules/page/website/protected/settings.php`;
    static thisProductBackendAttributeCreatePath: string = `${ApplicationConfig.thisProductBackendDirectory}/modules/page/attribute/protected/create.php`;
    static thisProductBackendAttributeUpdatePath: string = `${ApplicationConfig.thisProductBackendDirectory}/modules/page/attribute/protected/update.php`;
    static thisProductBackendActivityUpdatePath: string = `${ApplicationConfig.thisProductBackendDirectory}/modules/page/activities/protected/update.php`;
    static thisProductBackendActivityUpdateItemPath: string = `${ApplicationConfig.thisProductBackendDirectory}/modules/page/activities/protected/updateItem.php`;
}