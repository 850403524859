import { styled } from '@mui/material';
import StandartBottomSheetComponent from '../../shared/ui/StandartBottomSheetComponent';
import TemplateConfig from '../../../core/config/TemplateConfig';
import SimpleTextComponent from '../../shared/ui/SimpleTextComponent';
import LinkWrapperComponent from '../../shared/ui/LinkWrapperComponent';
import productPurchasedStore from '../../purchase/store/ProductPurchasedStore';
import OrderNoAccessComponent from '../../purchase/ui/OrderNoAccessComponent';
import { useState } from 'react';
// icons
import ProfileIcon from '../../../core/assets/module/customize/profile.svg';
import ParagraphIcon from '../../../core/assets/module/customize/paragraph.svg';
import ButtonIcon from '../../../core/assets/module/customize/button.svg';
import FormIcon from '../../../core/assets/module/customize/form.svg';
import DividerIcon from '../../../core/assets/module/customize/divider.svg';
import ImageIcon from '../../../core/assets/module/customize/image.svg';
import SocialIcon from '../../../core/assets/module/customize/social.svg';
import TimerIcon from '../../../core/assets/module/customize/timer.svg';
import LocationIcon from '../../../core/assets/module/customize/location.svg';
import SliderIcon from '../../../core/assets/module/customize/slider.svg';
import VideoIcon from '../../../core/assets/module/customize/video.svg';
import VerifyIcon from '../../../core/assets/module/customize/verify.svg';
import QuestionIcon from '../../../core/assets/module/customize/question.svg';
import RowIcon from '../../../core/assets/module/customize/row.svg';
// import PricelistIcon from '../../../core/assets/module/customize/pricelist.svg';
import AudioIcon from '../../../core/assets/module/customize/audio.svg';
import BannerIcon from '../../../core/assets/module/customize/banner.svg';
import MenuIcon from '../../../core/assets/module/customize/menu.svg';

export default function BlockAddBottomSheetComponent({
    navigate,
    open,
    onDismiss,
    onTextBlock,
    onDividerBlock,
    onButtonBlock,
    onImageBlock,
    onProfileBlock,
    onSocialBlock,
    onTimerBlock,
    onFormBlock,
    onSliderBlock,
    onMapBlock,
    onVideoBlock,
    onAppointmentBlock,
    onQuestionBlock,
    onRowBlock,
    onPricelistBlock,
    onAudioBlock,
    onBannerBlock,
    onMenuBlock
}: {
    navigate: any;
    open: boolean;
    onDismiss: any;
    onTextBlock: any;
    onDividerBlock: any;
    onButtonBlock: any;
    onImageBlock: any;
    onProfileBlock: any;
    onSocialBlock: any;
    onTimerBlock: any;
    onFormBlock: any;
    onSliderBlock: any;
    onMapBlock: any;
    onVideoBlock: any;
    onAppointmentBlock: any;
    onQuestionBlock: any;
    onRowBlock: any;
    onPricelistBlock: any;
    onAudioBlock: any;
    onBannerBlock: any;
    onMenuBlock: any;
}) {
    //items
    const listItems = [
        { onClick: onProfileBlock, icon: ProfileIcon, title: "Профиль", label: "profile" },
        { onClick: onTextBlock, icon: ParagraphIcon, title: "Текст", label: "text" },
        { onClick: onSocialBlock, icon: SocialIcon, title: "Ссылки", label: "social" },
        { onClick: onButtonBlock, icon: ButtonIcon, title: "Кнопка", label: "button" },
        { onClick: onDividerBlock, icon: DividerIcon, title: "Разделитель", label: "divider" },
        { onClick: onBannerBlock, icon: BannerIcon, title: "Баннер", label: "banner" },
        { onClick: onImageBlock, icon: ImageIcon, title: "Изображение", label: "image" },
        { onClick: onSliderBlock, icon: SliderIcon, title: "Слайдер", label: "slider" },
        { onClick: onVideoBlock, icon: VideoIcon, title: "Видео", label: "youtube" },
        { onClick: onAudioBlock, icon: AudioIcon, title: "Проигрыватель", label: "audio" },
        { onClick: onTimerBlock, icon: TimerIcon, title: "Таймер", label: "timer" },
        { onClick: onFormBlock, icon: FormIcon, title: "Форма", label: "form" },
        // { onClick: onPricelistBlock, icon: PricelistIcon, title: "Прайc лист", label: "pricelist" },
        { onClick: onMenuBlock, icon: MenuIcon, title: "Меню", label: "menu" },
        // { onClick: onMapBlock, icon: LocationIcon, title: "Карта", label: "map" },
        { onClick: onQuestionBlock, icon: QuestionIcon, title: "Вопрос и ответ", label: "question" },
        { onClick: onRowBlock, icon: RowIcon, title: "Медиа и текст", label: "row" },
        // { onClick: onAppointmentBlock, icon: AppointmentIcon, title: "Запись", label: "appointment" }
    ];

    //style
    const ListWrapper = styled('div')({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '10px'
    });
    const ListItemRow = styled('div')({
        width: `calc((100% - ${window.innerWidth > TemplateConfig.mobileMaxWidth ? 10 : 0}px) / ${window.innerWidth > TemplateConfig.mobileMaxWidth ? 2 : 1})`,
        boxSizing: 'border-box',
    });
    const ListItemWrapper = styled('div')({
        padding: '10px',
        border: `1px solid ${TemplateConfig.greyBorderColor}`,
        borderRadius: TemplateConfig.borderRadius,
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '40px auto 40px'
    });


    // order access
    const [orderAccessState, setOrderAccessState] = useState(false);

    return (
        <div>
            <StandartBottomSheetComponent
                open={open} onDismiss={onDismiss} onSubmit={null} title={'Новый блок'}>
                <ListWrapper>
                    {
                        listItems.map((field, index) => (
                            <ListItemRow key={index}>
                                <LinkWrapperComponent onClick={() => {
                                    if (!productPurchasedStore.purchasedData || !productPurchasedStore.purchasedData.block.includes(field.label)) setOrderAccessState(true);
                                    else field.onClick();
                                }}>
                                    <ListItemWrapper>
                                        <img width={24} style={{ paddingRight: 10 }} src={field.icon} alt="list item icon" />
                                        <SimpleTextComponent type='hardMedium'>
                                            {field.title}
                                        </SimpleTextComponent>
                                        {
                                            !productPurchasedStore.purchasedData || !productPurchasedStore.purchasedData.block.includes(field.label)
                                                ? <img width={24} style={{ justifySelf: 'end' }} src={VerifyIcon} alt="verify icon" />
                                                : null
                                        }
                                    </ListItemWrapper>
                                </LinkWrapperComponent>
                            </ListItemRow>
                        ))
                    }
                </ListWrapper>
            </StandartBottomSheetComponent>
            <OrderNoAccessComponent open={orderAccessState} onDismiss={() => setOrderAccessState(false)} navigate={navigate} />
        </div>
    );
};
